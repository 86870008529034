//  Widths
$layout__max-width: 1920px;
$layout__max-width_no-unit: 1920;
$layout-indent__width: 40px;

@include min-screen($screen__m) {
  .page-layout-2columns-right,
  .page-layout-2columns-left {
    .columns {
      .sidebar-main {
        width: 240px;
      }

      .column.main {
        width: calc(100% - 260px);
      }
    }
  }
}

@include min-screen($screen__m + 1) {
  .page-layout-2columns-right {
    .columns {
      .sidebar-main {
        padding-left: $indent__base;
      }
    }

  }

  .page-layout-2columns-left {
    .columns {
      .sidebar-main {
        padding-right: $indent__base;
      }
    }
  }
}