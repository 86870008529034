// specific styling for cart page
@media print {
  .checkout-cart-index {
    word-wrap: break-word;

    .page-main {
      margin-top: 0;
      padding-top: 0;
    }

    .page-title {
      padding-left: 10px;
    }

    #notice-cookie-block,
    #tsbadge4_db8d3657bdbe440c985ae127463eaad4,
    .action.primary.checkout,
    .messages,
    .menu-container,
    .cart-container .actions,
    .icon-print,
    .crosssell,
    .item-actions,
    footer {
      display: none !important;
    }

    .cart-container {
      .cart-summary {
        float: right;
        width:32%;
      }

      .form-cart {
        float: left;
        width: 65%;
      }
    }
  }
}
